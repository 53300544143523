.pagination {
	font-weight: $pagination-font-weight;
	flex-wrap: wrap;

	& .page-link {
		border-top-right-radius: 6px !important;
		border-top-left-radius: 6px !important;
		border-bottom-right-radius: 6px !important;
		border-bottom-left-radius: 6px !important;
	}
}