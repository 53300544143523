.touchspin {

  @extend .input-group;
  @extend .input-group-sm;
  @extend .mb-5;


  @media screen and (max-width: 1200px) {
    position: relative;
    display: inline-block;
    vertical-align: middle;


    .touchspin-button-group-start {
      margin-right: 0px;
    }

    .touchspin-button-group-end {
      margin-left: 0px;
    }

    .input-touchspin {
      width: 100%;
    }

    .btn {
      display: block;
      width: 100%;
      text-align: center;
    }

    .btn-top {
      border-radius: 3px 3px 0px 0px !important;
    }

    .btn-bottom {
      border-radius: 0px 0px 3px 3px !important;
    }

  }
}
