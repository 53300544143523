.table {
	&.table-card,
	&.table-panel {
		& > thead,
		& > tbody,
		& > tfoot {
			& > tr {
				& > th,
				& > td {
					&:first-child {
						padding-left: $spacer;
						// replace to update style
						border-radius: 10px 0 0 10px !important;
						
						@if $enable-rtl {
							border-right: 0;
						} @else {
							border-left: 0;
						}
					}
					&:last-child {
						padding-right: $spacer;
						// replace to update style
						border-radius: 10px 0 0 10px !important;
						
						@if $enable-rtl {
							border-left: 0;
						} @else {
							border-right: 0;
						}
					}
				}
			}
		}
	}
	// add to update style
	&.table-striped {
		&.striped-rounded {
			& > tbody {
				& > tr {
					& > th,
					& > td {
						&:first-child {
							border-radius: 6px 0px 0px 6px !important;
						}
						&:last-child {
							border-radius: 0px 6px 6px 0px !important;
						}
					}
				}
			}
		}
	}

	// add to update style
	&.table-dark {
		& > :not(:last-child) > :last-child > * {
			border-bottom-color: $gray-600;
		}
	}
}