.touchspin-order {
  min-width: 150px;
  text-align: 'center';

  @media screen and (max-width: 1200px) {
    min-width: 60px;
    max-width: 80px;
  }
}

.discount-input-order {
  min-width: 90px;
  max-width: 100px;
  text-align: 'center';
  input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
