/*!
 * Color Admin v5.1.4 (https://seantheme.com/color-admin/)
 * Copyright 2022 SeanTheme
 */

@import 'functions';
@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'app';
@import 'layout';
@import 'ui';
@import 'pages';
@import 'plugins';
@import 'helper';
@import 'rtl';
@import 'custom';