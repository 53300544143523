.card {
	&.border-0 {
		box-shadow: inset 0 0 0 0px var(--app-component-border-color-transparent);
	}
	
	& .nav-tabs .nav-item .nav-link {
		margin-bottom: 0;
	}
}

.card-header {
	background-color: var(--app-component-bg) !important;
}

.card-footer {
	background-color: var(--app-component-bg) !important;
}