// Variables
$dark-color-1: #373737;
$dark-color-2: #242b30;
$dark-color-3: #2d353c;
$dark-primary: #f65b58;

.dark-mode {
  .swal2-show {
    background: $dark-color-1 !important;
  }

  .swal2-title {
    color: var(--bs-body-color) !important;
  }

  .swal2-content {
    color: var(--bs-body-color) !important;
  }

  .swal2-hide {
    background: $dark-color-1 !important;
  }

  .swal2-icon.swal2-success.swal2-icon-show {
    .swal2-success-circular-line-right {
      background: $dark-color-1 !important;
    }

    .swal2-success-circular-line-left {
      background: $dark-color-1 !important;
    }

    .swal2-success-fix {
      background: $dark-color-1 !important;
    }
  }

}

a {
  text-decoration-line: none !important; 
}