.app-sidebar-minified:not(.app-without-sidebar) {
	& .app-sidebar:not(.app-sidebar-end) {
		width: $app-sidebar-minified-width;
		
		& .menu {
			& > .menu-item {
				& > .menu-link {
					padding: rem(10px) rem(15px);
					margin: 0;
					color: $gray-600;
					
					border-radius: 0;
					
					& .menu-text,
					& .menu-label,
					& .menu-badge {
						display: none;
					}
					& .menu-icon {
						margin: 0 auto;
						
						& .material-icons {
							font-size: rem(24px);
						}
					}
					& .menu-icon-img {
						margin: 0 auto;
						width: rem(24px);
						height: rem(24px);
					}
				}
				&.active {
					& > .menu-link {
						background: $primary;
						color: $white;
						
						& .menu-caret {
							color: $white;
						}
					}
				}
				&.has-sub > .menu-submenu {
					display: none !important;
				}
			}
		}
		& .menu-profile,
		& .menu-header,
		& .menu-search {
			display: none !important;
		}
		& .menu-caret {
			font-size: rem(14px);
			
			@if $enable-rtl {
				right: auto;
				left: rem(2px);
			} @else {
				left: auto;
				right: rem(2px);
			}
			
			&:before {
				opacity: 1;
				
				transform: rotate(0deg) !important;
			}
		}
		& .slimScrollDiv {
			& .slimScrollBar,
			& .slimScrollRail {
				display: none !important;
			}
		}
		& .ps__rail-x,
		& .ps__rail-y {
			display: none !important;
		}
	}
	& .app-sidebar-bg:not(.app-sidebar-end) {
		width: $app-sidebar-minified-width;
	}
	& .app-sidebar-minify-btn {
		& i {
			&:before {
				content: '\f101';
			}
		}
	}
	& .app-content {
		@include media-breakpoint-up(md) {
			@if $enable-rtl {
				margin-right: $app-sidebar-minified-width;
			} @else {
				margin-left: $app-sidebar-minified-width;
			}
		}
	}
	
	&.app-with-end-sidebar {
		& .app-content {
			@include media-breakpoint-up(md) {
				@if $enable-rtl {
					margin-left: $app-sidebar-minified-width;
					margin-right: auto;
				} @else {
					margin-right: $app-sidebar-minified-width;
					margin-left: auto;
				}
			}
		}
	}
}